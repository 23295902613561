import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

const Container = styled.footer`
  height: 100px;
  padding: 50px 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FooterLink = styled(Link)`
  padding: 0 20px;
  font-size: 16px;
  text-decoration: none;
`

const Footer = () => (
  <Container>
    <FooterLink to="/terms">
      Terms of Use
    </FooterLink>
    <FooterLink to="/privacy">
      Privacy
    </FooterLink>
  </Container>
)

export default Footer
