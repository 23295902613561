import styled from "@emotion/styled"

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 730px;
  padding: 0 20px 20px;
  box-sizing: border-box;
`

export default Container
