import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Container from "../components/container"
import Header from "../components/header"
import Footer from "../components/footer"

const StyledContainer = styled(Container)`
  h1 {
    font-size: 44px;
    line-height: 50px;
  }

  h2 {
    font-size: 30px;
    line-height: 36px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
  }

  blockquote {
    font-style: italic;
    background: #eee;
    padding: 1em 40px;
    margin: 0;
  }
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark
  return (
    <>
      <Header />
      <Layout>
        <StyledContainer>
          <h1>{frontmatter.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </StyledContainer>
      </Layout>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`