import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { useLocation } from '@reach/router'

const Container = styled.header`
  height: 50px;
  padding: 0 60px;
  display: flex;
  align-items: center;
  background: #75b09c;

`

const HomeLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
`

function Header({ siteTitle }) {
  return (
    <Container>
      <HomeLink to="/">
        ooloo.io
      </HomeLink>
    </Container>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
